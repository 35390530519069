<template>
  <div>
    <head-layout :head-title="$t('cip.dc.qualityDComission.title2')"> </head-layout>
    <div class="headers" style="display: flex">
      <el-input
        :placeholder="$t('cip.dc.qualityDComission.judgeName')"
        v-model="listParms.judgeName"
        class="hinput"
        style="width: 200px"
      />

      <el-select
        v-model="listParms.judgeResult"
        :placeholder="$t('cip.dc.qualityDComission.judgeResult')"
        clearable
      >
        <el-option :label="$t('cip.dc.qualityDComission.zhengchang')" value="false"> </el-option>
        <el-option :label="$t('cip.dc.qualityDComission.ychang')" value="true"> </el-option>
      </el-select>
      <!-- <el-date-picker
      v-model="listParms.startTime"
      type="datetime"
      placeholder="选择开始时间">
    </el-date-picker>
    <el-date-picker
      v-model="listParms.startTime"
      type="datetime"
      placeholder="选择结束时间">
    </el-date-picker> -->

      <el-date-picker
        v-model="timeparms"
        type="daterange"
        @change="selectTime"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        :end-placeholder="$t('cip.dc.DComission.enddate')"
                        :range-separator="$t('cip.dc.DComission.to')"
                        :start-placeholder="$t('cip.dc.DComission.startdate')"
      >
      </el-date-picker>

      <!-- <el-date-picker
     style="margin-left: 10px;height: 28px;line-height: 28px;"
      v-model="timeparms"
      type="datetimerange"
      @change="selectTime"
      format="yyyy-MM-dd HH:mm:ss"
      value-format="yyyy-MM-dd HH:mm:ss"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">

    </el-date-picker> -->

      <el-button-group style="margin-left: 10px">
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-search"
          @click="onLoad"
        ></el-button>
        <el-button
          size="mini"
          icon="reset-refresh icon-refresh"
          @click="czFn"
        ></el-button>
      </el-button-group>
    </div>

    <grid-layout
      ref="gridLayout"
      :data-total="total"
      @page-size-change="handleSizeChange"
      @page-current-change="handleCurrentChange"
      @page-refresh-change="onLoad"
      :page="page"
      @gird-handle-select-click="handleSelectionChange"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :gridRowBtn="gridRowBtn"
      @grid-view="toSee"
      :searchColumns="searchColumns"
    >
     <template slot="judgeName" slot-scope="{ row }">
        <span v-show="row.judgeResult=='正常'">{{row.judgeName}}</span>
         <span style="color:red" v-show="row.judgeResult=='异常'">{{row.judgeName}}</span>
      </template>

       <template slot="judgeResult" slot-scope="{ row }">
        <span v-show="row.judgeResult=='正常'">{{$t('omit.Normal')}}</span>
         <span style="color:red" v-show="row.judgeResult=='异常'">{{$t('omit.anomaly')}}</span>
      </template>

      <template slot="createTime" slot-scope="{ row }">
        <span v-show="row.judgeResult=='正常'">{{row.createTime}}</span>
         <span style="color:red" v-show="row.judgeResult=='异常'">{{row.createTime}}</span>
      </template>


    </grid-layout>

    <el-dialog
    :title="$t('cip.dc.DComission.tip')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>{{ dialogtext }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('cip.dc.DComission.false') }}</el-button>
        <el-button type="primary" @click="SureToExecute">{{ $t('cip.dc.DComission.sure') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

  <script>
import { judgeRecordList } from "@/api/dataAcquisition/index";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";

export default {
  components: {
    HeadLayout,
    GridLayout,
  },
  props: {},
  data() {
    return {
      timeparms: "",
      total: "",
      dialogtext: "",
      runState: "",
      btnType: "",
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      dialogVisible: false,
      tableLoading: false,
      ExecuteId: "",
      tableData: [],
      listParms: {
        pageNum: 1,
        pageSize: 10,
        judgeName: "",
        startTime: "",
        endTime: "",
        judgeResult: "",
      },
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.viewBtn"),
          emit: "grid-view",
          remark: "view",
          type: "text",
          icon: "",
        },
      ],

      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 200,
        column: [
          {
            label: this.$t('cip.dc.qualityDComission.judgeName'),
            prop: "judgeName",
            cell: true,
            slot:true
          },
          {
            label: this.$t('cip.dc.qualityDComission.judgeResult'),
            prop: "judgeResult",
            cell: true,
            slot:true
          },
          {
            label: this.$t('cip.dc.qualityDComission.createTime'),
            prop: "createTime",
            cell: true,
            slot:true
          },
        ],
      },
    };
  },

  methods: {
    selectTime(e) {
      this.listParms.startTime = e[0];
      this.listParms.endTime = e[1];
    },
    //获取列表
    getList(parms) {
      console.log(parms);
      judgeRecordList(parms).then((res) => {
        this.tableData = res.data.data.dataList;
        this.total = res.data.data.page.totalSize;
      });
    },
    onLoad() {
      this.listParms.pageNum = 1;
      this.page.currentPage = 1
      this.getList(this.listParms);
    },
    czFn() {
      this.listParms.pageNum = 1;
      this.page.currentPage = 1;

      this.listParms.pageSize = 10;
      this.page.pageSize = 10
      this.listParms.judgeName = "";
      this.listParms.startTime = "";
      this.listParms.endTime = "";
      this.timeparms = "";
      this.listParms.judgeResult = "",
      this.getList(this.listParms);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.listParms.pageSize = e.pageSize;
      this.page.currentPage = 1;
      this.listParms.pageNum = 1;
      this.getList(this.listParms);
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.listParms.pageNum = e.currentPage;
      this.getList(this.listParms);
    },

    //查看
    toSee(item) {
      this.$router.push({
        path: "/Quality/judgeTaskAdd",
        query: {
          type: "seeResult",
          id: item.id,
        },
      });
    },
  },
  mounted() {
    this.getList(this.listParms);
  },
};
</script>
  <style  scoped>
  .el-select {
     width: 200px !important;
}
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}
</style>
